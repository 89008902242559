/**
 * This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import { Helmet } from "react-helmet";
import AppCard from "../../components/AppCard";
import { Link } from "react-router-dom";


function The7Zip() {
  function downloadWindows32App(appId) {
    window.location.href = `/apps/${appId}/download/x86`;
  }

  function downloadWindows64App(appId) {
    window.location.href = `/apps/${appId}/download/x64`;
  }
  const App = {
    name: "7 Zip",
    id: "7zip",
    version: "Latest",
    imgUrl: "//images/7zip.png",
    brand: "7-Zip",
    brandUrl: "https://www.7-zip.org/",
    metaKeywords:
      "7zip, 7zip file extractor, download zip software, free download unzip software, file compressor, file extractor",
  };

  return (
    <div>
      <Helmet>
        <title>Download {App.name} For Free (32/64 bit) - Spexware</title>
        <meta
          name="description"
          content={`Download ${App.name} Software and/or more software from spexware.com`}
        />
        <meta name="keywords" content={App.metaKeywords} />
      </Helmet>
      {/* <SearchBar /> */}
      <div className="container px-4 py-4">
        <div className="row align-items-start my-0">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <Link to="/" style={{ textDecoration: `none` }}>
              <i className="fa fa-arrow-left"></i> &nbsp; All Apps
            </Link>{" "}
            <h1 className="my-3 mt-5">{App.name}</h1>
            <p className="text-dark" style={{ fontSize: `15px` }}>
              From{" "}
              <span style={{ fontSize: `15px` }}>
                <a
                  href={`${App.brandUrl}?ref_by=spexware.com`}
                  target="_ublank"
                  className="text-primary no-decor"
                >
                  {App.brand}
                </a>
              </span>{" "}
              <i
                className="fa fa-check-circle text-primary"
                title="Verified"
              ></i>
              &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;Version:{" "}
              <span className="text-primary fs-15">{App.version}</span>
            </p>
            <div className="row">
              <div className="col-12 app-desc">
                <p>
                  The 7-Zip is a free and open-source file archiver, a utility
                  used to place groups of files within compressed containers
                  known as "archives". It is developed by Igor Pavlov and was
                  first released in 1999. 7-Zip uses its own 7z archive format,
                  but can read and write several other archive formats.
                </p>
                <p>
                  The 7-Zip comes with a file manager along with the standard
                  archiver tools. The file manager has a toolbar with options to
                  create an archive, extract an archive, test an archive to
                  detect errors, copy, move, and delete files, and open a file
                  properties menu exclusive to 7-Zip. The file manager, by
                  default, displays hidden files because it does not follow
                  Windows Explorer's policies. The tabs show name, modification
                  time, original and compressed sizes, attributes, and comments
                  (4DOS descript.ion format).
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
            <div className="row my-3">
              <div className="col-12">
                <img
                  src={App.imgUrl}
                  className="img-fluid px-5 py-5"
                  alt={App.name}
                  style={{ maxHeight: `300px`, textAlign: `center` }}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="download-group">
                  <div className="btn-group">
                    <button
                      rel="nofollow"
                      disabled
                      className="input-group-text"
                    >
                      <i className="fab fa-windows"></i>&nbsp;&nbsp;Windows
                    </button>
                    <button
                      rel="nofollow"
                      className="btn btn-primary btn-sm"
                      data-toggle="tooltip"
                      title="Start download"
                      onClick={() => downloadWindows32App(App.id)}
                    >
                      Download 32-bit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="download-group">
                  <div className="btn-group">
                    <button
                      rel="nofollow"
                      disabled
                      className="input-group-text"
                    >
                      <i className="fab fa-windows"></i>&nbsp;&nbsp;Windows
                    </button>

                    <button
                      rel="nofollow"
                      className="btn btn-primary btn-sm"
                      data-toggle="tooltip"
                      title="Start download"
                      onClick={() => downloadWindows64App(App.id)}
                    >
                      Download 64-bit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
       
            </div>
          </div>
        </div>
      </div>
      <div className="container my-4 px-4">
        <p className="text-muted small">{App.name} users also like</p>
        <div className="row text-center">
          <AppCard
            name="WinRAR"
            imgUrl="/images/winrar.png"
            appId="winrar"
          />
          <AppCard
            name="KM PLayer"
            imgUrl="/images/kmplayer.png"
            version="4.2.2.55"
            appId="km-player"
          />
          <AppCard
            name="LD Player"
            imgUrl="/images/ldplayer.png"
            version="4.0"
            appId="ld-player"
          />
          <AppCard
            name="BlueStacks"
            imgUrl="/images/bluestacks.jpg"
            appId="bluestacks"
          />
          <AppCard
            name="WhatsApp"
            imgUrl="/images/whatsapp.png"
            appId="whatsapp"
          />
          <AppCard
            name="VS Code"
            imgUrl="/images/vscode.png"
            version="1.60.0"
            appId="vs-code"
          />
        </div>
      </div>
    </div>
  );
}

export default The7Zip;
