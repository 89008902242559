/**
 * This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import React from "react";
import { Helmet } from "react-helmet";

function AboutPage() {
  return (
    <div className="mt-5">
      <Helmet>
        <title>About - Spexware</title>
        <meta
          name="description"
          content="Learn more about our mission at spexware.com"
        />
        <meta
          name="keywords"
          content="about spexware, about spexware mission,"
        />
        <meta name="author" content="Spexware Team" />
      </Helmet>
      <div className="mb-4">
        <div className="container pl-5 pr-5 pt-5 pb-5">
          <h1 className="my-4">About</h1>
          <p style={{ fontSize: `22px` }}>
            Our mission is to serve software downloads at high speed without
            compromising the user experience. To make sure you don't get
            frustrated from browsing our site, we're using Facebook's Open
            Source framework{" "}
            <a href="https://reactjs.org/" target="_ublank">
              React JS
            </a>{" "}
            which makes it easy for you to browse our site more effeciently. To
            serve downloads we've chosen{" "}
            <a href="https://cloud.google.com/cdn/" target="_ublank">
              Google Cloud CDN
            </a>{" "}
            which provides you content from the servers nearest to your
            location.
          </p>
          <h4 className="mt-5 mb-4">We're quite social</h4>
          <p>
            <a
              className="btn btn-primary"
              href="https://www.facebook.com/spexware"
              target="_ublank"
            >
              Like us on Facebook
            </a>
          </p>
          <p>
            <a
              className="btn btn-primary"
              href="https://twitter.com/spexware"
              target="_ublank"
            >
              Follow us on Twitter
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
