/**
 * This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import { Helmet } from "react-helmet";
import AppCard from "../../components/AppCard";
import { Link } from "react-router-dom";


function Anydesk() {
  function downloadWindows32App(appId) {
    window.location.href = `/apps/${appId}/download/x86`;
  }

  function downloadWindows64App(appId) {
    window.location.href = `/apps/${appId}/download/x64`;
  }

  const App = {
    name: "AnyDesk",
    id: "any-desk",
    version: "Latest",
    imgUrl: "/images/anydesk.png",
    brand: "Anydesk",
    brandUrl: "https://anydesk.com/",
    metaKeywords:
      "anydesk, download anydesk, free anydesk, latest anydesk, remote desktop",
  };

  return (
    <div>
      <Helmet>
        <title>Download {App.name} For Free (32/64 bit) - Spexware</title>
        <meta
          name="description"
          content={`Download ${App.name} Software and/or more software from Spexware.com`}
        />
        <meta name="keywords" content={App.metaKeywords} />
      </Helmet>
      {/* <SearchBar /> */}
      <div className="container px-4 py-4">
        <div className="row align-items-center my-0">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <Link to="/" style={{ textDecoration: `none` }}>
              <i className="fa fa-arrow-left"></i> &nbsp; All Apps
            </Link>{" "}
            <h1 className="my-3 mt-5">{App.name}</h1>
            <p className="text-dark" style={{ fontSize: `15px` }}>
              From{" "}
              <span style={{ fontSize: `15px` }}>
                <a
                  href={`${App.brandUrl}?ref_by=spexware.com`}
                  target="_ublank"
                  className="text-primary no-decor"
                >
                  {App.brand}
                </a>
              </span>{" "}
              <i
                className="fa fa-check-circle text-primary"
                title="Verified"
              ></i>
              &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;Version:{" "}
              <span className="text-primary fs-15">{App.version}</span>
            </p>
            <div className="row">
              <div className="col-12 app-desc">
                <p>
                AnyDesk is a fast, secure, and user-friendly remote desktop software that allows you to access computers from anywhere. 
                It's available for Windows, macOS, Linux, and mobile platforms, providing seamless remote access.
                </p>
                <p>
                With AnyDesk, you can connect to a remote desktop, share screens, and even control a device from afar. Whether it's for remote support, administration, or collaboration, AnyDesk offers high performance with low latency for smooth interactions.
                </p>
                <p>
                Easily transfer files between connected devices, and perform editing or configuration tasks remotely. AnyDesk supports multiple monitors, so you can view and work across screens effortlessly.
                </p>

                <p>
                Robust security features such as TLS 1.2 encryption ensure your data and connections remain safe and private.
                </p>
               
            
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
            <div className="row my-3">
              <div className="col-12">
                <img
                  src={App.imgUrl}
                     className="img-fluid px-5 py-5"
                  alt={App.name}
                  style={{ maxHeight: `300px`, textAlign: `center` }}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="download-group">
                  <div className="btn-group">
                    <button
                      rel="nofollow"
                      disabled
                      className="input-group-text"
                    >
                      <i className="fab fa-windows"></i>&nbsp;&nbsp;Windows
                    </button>
                    <button
                      rel="nofollow"
                      className="btn btn-primary btn-sm"
                      data-toggle="tooltip"
                      title="Start download"
                      onClick={() => downloadWindows32App(App.id)}
                    >
                      Download 32-bit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="download-group">
                  <div className="btn-group">
                    <button
                      rel="nofollow"
                      disabled
                      className="input-group-text"
                    >
                      <i className="fab fa-windows"></i>&nbsp;&nbsp;Windows
                    </button>

                    <button
                      rel="nofollow"
                      className="btn btn-primary btn-sm"
                      data-toggle="tooltip"
                      title="Start download"
                      onClick={() => downloadWindows64App(App.id)}
                    >
                      Download 64-bit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-4 px-4">
        <p className="text-muted small">{App.name} users also like</p>
        <div className="row text-center">
          <AppCard
            name="K Lite Codec Pack"
            imgUrl="/images/klite.png"
            appId="klite-codec-pack"
          />
          <AppCard
            name="KM PLayer"
            imgUrl="/images/kmplayer.png"
            version="4.2.2.55"
            appId="km-player"
          />
          <AppCard
            name="LD Player"
            imgUrl="/images/ldplayer.png"
            version="4.0"
            appId="ld-player"
          />
          <AppCard
            name="BlueStacks"
            imgUrl="/images/bluestacks.jpg"
            appId="bluestacks"
          />
          <AppCard
            name="WhatsApp"
            imgUrl="/images/whatsapp.png"
            appId="whatsapp"
          />
          <AppCard
            name="VS Code"
            imgUrl="/images/vscode.png"
            version="1.60.0"
            appId="vs-code"
          />
        </div>
      </div>
    </div>
  );
}

export default Anydesk;
