/**
 * This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import React from "react";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light ">
      <div className="container-fluid">
        <a className="navbar-brand fs-16" href="/">
          <img
            src={`/images/logo192.png`}
            alt="Spexware"
            className="img-fluid"
            width="40px"
          />{" "}
          Spexware
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Apps
              </a>
            </li>
           
          </ul>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
           {/*  <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Home
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/blog" hidden>
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item d-flex">
              <a
               className="nav-link"
                href="/contact"
                tabIndex="-1"
                aria-disabled="true"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
