/**
* This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import { Helmet } from "react-helmet";
// import HeroIntro from "../components/Jumbotrons/Intro";
import SearchBar from "../components/Search/SearchBar";
// import AppCard from "../components/AppCard";
// import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// function pickRandom(min, max) {
//   return Math.floor(Math.random() * (max - min + 1) + min);
// }
// async function task(setCount, count, i) {
//   await timer(1000);
//   setCount(count + i);
// }

// async function main(setCount, count) {
//   for (let i = 0; i < 1000; i += 100) {
//     for (let j = 0; j < 10; j++) {
//       if (j % 2) {
//         await task(setCount, count, i + j);
//       }
//     }
//   }
// }

// function timer(ms) {
//   return new Promise((res) => setTimeout(res, ms));
// }

// async function loop(setCount) {
//   for (let i = 0; i < 100; i++) {
//     await setTimeout(() => {
//       setCount(i);
//     }, 1000);
//   }
// }

function HomePage() {
  // const [count, setCount] = useState(0);

  // for (let i = 0; i <= 100; i++) {
  //   setTimeout(() => {
  //     setCount(i + pickRandom(1, 5));
  //     console.log("Hi");
  //   }, pickRandom(100, 800));
  // }

  let apps = [
    {
      id: 0,
      name: "Filmora",
      keywords: "filmora, video editor",
      imgUrl: "/images/filmora.png",
      appId: "filmora",
    },
    {
      id: 1,
      name: "Sublime Text",
      keywords: "sublime, text editor, code, programming, development, web",
      imgUrl: "/images/sublime.png",
      appId: "sublime-text",
    },
    {
      id: 2,
      name: "InPage",
      keywords: "inpage, in page, urdu editor",
      imgUrl: "/images/inpage.png",
      appId: "in-page",
    },
    {
      id: 3,
      name: "FDM",
      keywords: "fdm, free download manager, free internet download manager",
      imgUrl: "/images/fdm.png",
      appId: "free-download-manager",
    },
    {
      id: 4,
      name: "BlueStacks",
      keywords: "bluestacks, android emulator, gaming",
      imgUrl: "/images/bluestacks.jpg",
      appId: "bluestacks",
    },
    {
      id: 5,
      name: "AVC",
      keywords: "avc, any video converter",
      imgUrl: "/images/avc.jpeg",
      appId: "any-video-converter",
    },
    {
      id: 6,
      name: "IObit Uninstaller",
      keywords: "iobit uinstaller, cache cleaner",
      imgUrl: "/images/iobit.png",
      version: "11.0",
      appId: "iobit-uninstaller",
    },
    {
      id: 7,
      name: "Free PDF Reader",
      keywords: "free pdf reader",
      imgUrl: "/images/freepdfreader.png",
      appId: "free-pdf-reader",
    },
    {
      id: 8,
      name: "LD Player",
      keywords: "ld player, android emulator, gaming",
      imgUrl: "/images/ldplayer.png",
      version: "4.0",
      appId: "ld-player",
    },
    {
      id: 9,
      name: "Notepad++",
      keywords: "notepad++, code editor, programming, web development",
      imgUrl: "/images/notepad++.png",
      version: "7.9.3",
      appId: "notepad-plus-plus",
    },
    {
      id: 10,
      name: "WhatsApp",
      keywords: "whatsapp messenger",
      imgUrl: "/images/whatsapp.png",
      appId: "whatsapp",
    },
    {
      id: 11,
      name: "VLC Media Player",
      keywords:
        "vlc media player, audio player, music player, streaming server, codec",
      imgUrl: "/images/vlc.svg",
      version: "3.0.16",
      appId: "vlc-media-player",
    },
    {
      id: 12,
      name: "VS Code",
      keywords: "microsoft vs code, code editor, programming, web development",
      imgUrl: "/images/vscode.png",
      version: "1.60.2",
      appId: "vs-code",
    },
    {
      id: 13,
      name: "7 Zip",
      keywords:
        "7 zip, seven zip, 7zip extractor, unzipper, zipper, file extractor, file compressor",
      imgUrl: "/images/7zip.png",
      appId: "7zip",
    },
    {
      id: 14,
      name: "Audacity",
      keywords: "audacity sound recorder and studio, sound mixer",
      imgUrl: "/images/audacity.png",
      appId: "audacity",
    },
    {
      id: 15,
      name: "Avast",
      keywords: "avast antivirus, malware removal, cache cleaner",
      imgUrl: "/images/avast.png",
      appId: "avast-antivirus",
    },
    {
      id: 16,
      name: "Blender",
      keywords: "blender 3d modeler studio",
      imgUrl: "/images/blender.png",
      version: "2.9.3",
      appId: "blender",
    },
    {
      id: 17,
      name: "Camtasia",
      keywords:
        "techsmith camtasia studio, video editor, video recorder, live streaming",
      imgUrl: "/images/camtasia.png",
      appId: "camtasia",
    },
    {
      id: 18,
      name: "C Cleaner",
      keywords: "ccleaner, c cleaner, cache cleaner",
      imgUrl: "/images/ccleaner.png",
      appId: "ccleaner",
    },
    {
      id: 19,
      name: "Google Chrome",
      keywords: "google chrome browser, internet browser, best browser",
      imgUrl: "/images/chrome.png",
      appId: "google-chrome",
    },
    {
      id: 20,
      name: "Corel CAD",
      keywords: "corelcad, corel-cad, corel draw",
      imgUrl: "/images/corelcad.png",
      appId: "corel-cad",
    },
    {
      id: 21,
      name: "Mozilla Firefox",
      keywords: "mozilla firefox browser, internet browser, best browser",
      imgUrl: "/images/firefox.png",
      version: "93.0",
      appId: "mozilla-firefox",
    },
    {
      id: 22,
      name: "IDM",
      keywords: "idm, internet download manager",
      imgUrl: "/images/idm.png",
      appId: "idm",
    },
    {
      id: 23,
      name: "Inkscape",
      keywords:
        "inkscape editor, photo editor, image editor, image illustrator, svg editor, draw",
      imgUrl: "/images/inkscape.png",
      version: "1.1.1",
      appId: "inkscape",
    },
    {
      id: 24,
      name: "K Lite Codec Pack",
      keywords:
        "klite codec pack, k lite, k-lite, media player, audio player, video player",
      imgUrl: "/images/klite.png",
      appId: "klite-codec-pack",
    },
    {
      id: 25,
      name: "KM Player",
      keywords:
        "km player, media player, audio player, video player, codec, k m",
      imgUrl: "/images/kmplayer.png",
      version: "4.2.2.55",
      appId: "km-player",
    },
    {
      id: 26,
      name: "OBS Studio",
      keywords: "obs studio, obs streaming software, video streaming",
      imgUrl: "/images/obs.png",
      version: "26.1.1",
      appId: "obs-studio",
    },
    {
      id: 27,
      name: "WinRAR",
      keywords: "winrar extractor, file extractor, zipper, unzipper",
      imgUrl: "/images/winrar.png",
      appId: "winrar",
    },
    {
      id: 28,
      name: "Smadav",
      keywords: "smadav, samadav, antivirus, cleaner",
      imgUrl: "/images/smadav.png",
      appId: "smadav",
    },
    {
      id: 29,
      name: "Rufus",
      keywords: "rufus, bootable, iso, install window with usb",
      imgUrl: "/images/rufus.jpeg",
      appId: "rufus",
    },
    {
      id: 30,
      name: "AnyDesk",
      keywords: "anydesk, remote, live, free",
      imgUrl: "/images/anydesk.png",
      appId: "any-desk",
    },
  ].reverse();

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Download Free Software, Fast - Spexware</title>
        <meta name="description" content="Learn about spexware disclaimer." />
        <meta name="keywords" content="spexware discalimer, spexware license" />
        <meta name="author" content="Spexware Team" />
      </Helmet>
      <div className="container-fluid">
        <div className="container mt-5 mb-5">
          <SearchBar apps={apps} />
        </div>
        <div className="container mt-5 mb-5">
          {/* <div className="row">
            <div className="col-12 text-center">
              <div style={{ maxWidth: `300px` }}>
                <CircularProgressbarWithChildren value={count}>
                  <img
                    style={{ width: 40, marginTop: -5 }}
                    src="/logo192.png"
                    alt="Spexware"
                  />
                  <div style={{ fontSize: 12, marginTop: -5 }}>
                    <strong>{count}</strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
