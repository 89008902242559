/**
 * This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import React from "react";
import { Helmet } from "react-helmet";

function ContactPage() {
  return (
    <div className="mt-5">
      <Helmet>
        <title>Contact - Spexware</title>
        <meta
          name="description"
          content="Learn how you can contact spexware to request new software or write some suggestion at spexware"
        />
        <meta
          name="keywords"
          content="contact spexware, request new software spexware, message spexware, reach spexware team, contact spexware team"
        />
        <meta name="author" content="Spexware Team" />
      </Helmet>
      <div className="mb-4">
        <div className="container pl-5 pr-5 pt-5 pb-5">
          <h1>Let's Connect</h1>
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <p>Please, leave a message. We shall get back to you asap.</p>
              <div className="row">
                <form className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                  <div className="form-group mt-4 mb-4">
                    <input
                      type="text"
                      name="Name"
                      placeholder="Your name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mt-4 mb-4">
                    <input
                      type="email"
                      name="Email"
                      placeholder="Email address"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mt-4 mb-4">
                    <textarea
                      name="message"
                      rows="7"
                      placeholder="Enter your message here"
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="form-group mt-4 mb-4">
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <p>
                <a
                  className="btn btn-primary"
                  href="mailto:teamspexware@gmail.com"
                  target="_ublank"
                >
                  <i className="fa fa-envelope"></i> &nbsp;Write us an Email
                </a>
              </p>
              <p>
                <a
                  className="btn btn-primary"
                  href="https://www.facebook.com/spexware"
                  target="_ublank"
                >
                  <i className="fa fa-facebook"></i> &nbsp;Like us on Facebook
                </a>
              </p>
              <p>
                <a
                  className="btn btn-primary"
                  href="https://twitter.com/spexware"
                  target="_ublank"
                >
                  <i className="fa fa-twitter"></i> &nbsp;Follow us on Twitter
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
