/**
 * This source code is a private property and must not be distributed,
 * published or used without explicit permission of the owner.
 */

import { Helmet } from "react-helmet";
import AppCard from "../../components/AppCard";
import { Link } from "react-router-dom";


function OBS() {
  function downloadWindows32App(appId) {
    window.location.href = `/apps/${appId}/download/x86`;
  }

  function downloadWindows64App(appId) {
    window.location.href = `/apps/${appId}/download/x64`;
  }

  const App = {
    name: "OBS Studio",
    id: "obs-studio",
    version: "26.1.1",
    imgUrl: "/images/obs.png",
    brand: "OBS Project",
    brandUrl: "https://obsproject.com/",
    metaKeywords:
      "obs, download obs studio, best free broadcasting software, video editors",
  };

  return (
    <div>
      <Helmet>
        <title>Download {App.name} For Free (32/64 bit) - Spexware</title>
        <meta
          name="description"
          content={`Download ${App.name} Software and/or more software from spexware.com`}
        />
        <meta name="keywords" content={App.metaKeywords} />
      </Helmet>
      {/* <SearchBar /> */}
      <div className="container px-4 py-4">
        <div className="row align-items-center my-0">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <Link to="/" style={{ textDecoration: `none` }}>
              <i className="fa fa-arrow-left"></i> &nbsp; All Apps
            </Link>{" "}
            <h1 className="my-3 mt-5">{App.name}</h1>
            <p className="text-dark" style={{ fontSize: `15px` }}>
              From{" "}
              <span style={{ fontSize: `15px` }}>
                <a
                  href={`${App.brandUrl}?ref_by=spexware.com`}
                  target="_ublank"
                  className="text-primary no-decor"
                >
                  {App.brand}
                </a>
              </span>{" "}
              <i
                className="fa fa-check-circle text-primary"
                title="Verified"
              ></i>
              &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;Version:{" "}
              <span className="text-primary fs-15">{App.version}</span>
            </p>
            <div className="row">
              <div className="col-12 app-desc">
                <p>
                  OBS Studio is equipped with a powerful API, enabling plugins
                  and scripts to provide further customization and functionality
                  specific to your needs. Utilize native plugins for high
                  performance integrations or scripts written with Lua or Python
                  that interface with existing sources. Work with developers in
                  the streaming community to get the features you need with
                  endless possibilities.
                </p>
                <p>
                  High performance real time video/audio capturing and mixing.
                  Create scenes made up of multiple sources including window
                  captures, images, text, browser windows, webcams, capture
                  cards and more.
                </p>
                <p>
                  Set up an unlimited number of scenes you can switch between
                  seamlessly via custom transitions.
                </p>
                <p>
                  Choose from a number of different and customizable transitions
                  for when you switch between your scenes or add your own
                  stinger video files.
                </p>
                <p>
                  Set hotkeys for nearly every sort of action, such as switching
                  between scenes, starting/stopping streams or recordings,
                  muting audio sources, push to talk, and more.
                </p>
              
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
            <div className="row my-3">
              <div className="col-12">
                <img
                  src={App.imgUrl}
                  className="img-fluid px-5 py-5"
                  alt={App.name}
                  style={{ maxHeight: `300px`, textAlign: `center` }}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="download-group">
                  <div className="btn-group">
                    <button
                      rel="nofollow"
                      disabled
                      className="input-group-text"
                    >
                      <i className="fab fa-windows"></i>&nbsp;&nbsp;Windows
                    </button>
                    <button
                      rel="nofollow"
                      className="btn btn-primary btn-sm"
                      data-toggle="tooltip"
                      title="Start download"
                      onClick={() => downloadWindows32App(App.id)}
                    >
                      Download 32-bit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="download-group">
                  <div className="btn-group">
                    <button
                      rel="nofollow"
                      disabled
                      className="input-group-text"
                    >
                      <i className="fab fa-windows"></i>&nbsp;&nbsp;Windows
                    </button>

                    <button
                      rel="nofollow"
                      className="btn btn-primary btn-sm"
                      data-toggle="tooltip"
                      title="Start download"
                      onClick={() => downloadWindows64App(App.id)}
                    >
                      Download 64-bit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-4 px-4">
        <p className="text-muted small">{App.name} users also like</p>
        <div className="row text-center">
          <AppCard
            name="K Lite Codec Pack"
            imgUrl="/images/klite.png"
            appId="klite-codec-pack"
          />
          <AppCard
            name="KM PLayer"
            imgUrl="/images/kmplayer.png"
            version="4.2.2.55"
            appId="km-player"
          />
          <AppCard
            name="LD Player"
            imgUrl="/images/ldplayer.png"
            version="4.0"
            appId="ld-player"
          />
          <AppCard
            name="BlueStacks"
            imgUrl="/images/bluestacks.jpg"
            appId="bluestacks"
          />
          <AppCard
            name="WhatsApp"
            imgUrl="/images/whatsapp.png"
            appId="whatsapp"
          />
          <AppCard
            name="VS Code"
            imgUrl="/images/vscode.png"
            version="1.60.0"
            appId="vs-code"
          />
        </div>
      </div>
    </div>
  );
}

export default OBS;
